
import { defineComponent } from "vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Detail from "@/components/home/Detail.vue";
import LifeStyle from "@/components/home/Lifestyle.vue";
import AIHeader from "@/components/AIHeader.vue";
import Users from "@/components/home/Users.vue";
import Story from "@/components/home/Story.vue";
import AIContentVue from "@/components/AIHome/AIContent.vue";
import { store } from "@/utils/store";

export default defineComponent({
  name: "Home",
  components: {
    Detail,
    Users,
    Header,
    AIHeader,
    Story,
    LifeStyle,
    AIContentVue,
    Footer,
  },
  data () {
    return {
      store
    }
  }
});
