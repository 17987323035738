
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
  props: {
    url: String,
    title: String,
    content: String,
    digest: String
  }
})
