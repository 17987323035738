<template>
  <div>
    <div class="screen-wrapper">
      <div class="screen" id="screen">
        <div>
          <router-view />
        </div>
      </div>
    </div>
    <div class="mobile_wrapper">
      <MobileHome v-if="!store.isLogin && init" />
      <div v-else>
        <AIHeaderVue />
        <AIContentVue />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import axios from "axios";
import AIHeaderVue from './components/AIHeader.vue';
import { store } from "@/utils/store";
import AIContentVue from "@/components/AIHome/AIContent.vue";
import MobileHome from "@/components/mobileHome/MobileHome";
export default defineComponent({
  name: 'app',
  components: {
    AIContentVue,
    MobileHome,
    AIHeaderVue,
  },
  data(){
    return {
      store,
      hidden: false,
      init: false,
    }
  },
  mounted() {
    var config = {
      method: 'get',
      url: '/api/ai-health/page-config/getTreeList',
    };
    axios(config).then(res => {
      console.log(res);
      const formatPics = res.data.data.find((item) => item.configCode === "PAGE_MODEL_1").children.map(item => {
        return ({
        title: JSON.parse(item.configValue.replace(/[\r|\n|\t]/g," ")).title.split(" "),
        url: JSON.parse(item.configValue.replace(/[\r|\n|\t]/g," ")).url,
      })
      })
      const PAGE_MODEL_1 = formatPics;
      const PAGE_TOP = res.data.data.find((item) => item.configCode === "PAGE_TOP");
      const PAGE_MODEL_2 = res.data.data.find((item) => item.configCode === "PAGE_MODEL_2");
      const FUN_ZONE = res.data.data.find((item) => item.configCode === "FUN_ZONE");
      const PAGE_MODEL_3 = res.data.data.find((item) => item.configCode === "PAGE_MODEL_3");
      const VIDEO_1 = res.data.data.find((item) => item.configCode === "VIDEO_1");
      window.localStorage.setItem("PAGE_MODEL_1", JSON.stringify(PAGE_MODEL_1));
      window.localStorage.setItem("PAGE_MODEL_2", JSON.stringify({
        title: JSON.parse(PAGE_MODEL_2.configValue.replace(/[\r|\n|\t]/g," ")).content.split(" "),
        url: JSON.parse(PAGE_MODEL_2.children[0].configValue.replace(/[\r|\n|\t]/g," ")).url,
      }));
      window.localStorage.setItem("PAGE_TOP", JSON.stringify({
        url: JSON.parse(PAGE_TOP.configValue.replace(/[\r|\n|\t]/g," ")).url,
      }));
      window.localStorage.setItem("PAGE_MODEL_3", JSON.stringify({
        url: JSON.parse(PAGE_MODEL_3.configValue.replace(/[\r|\n|\t]/g," ")).url,
      }));
      window.localStorage.setItem("VIDEO_1", JSON.stringify({
        demo: JSON.parse(VIDEO_1.configValue.replace(/[\r|\n|\t]/g," ")).demo,
        videoUrl: JSON.parse(VIDEO_1.configValue.replace(/[\r|\n|\t]/g," ")).videoUrl,
        imgUrl: JSON.parse(VIDEO_1.configValue.replace(/[\r|\n|\t]/g," ")).imgUrl,
      }));
      window.localStorage.setItem("FUN_ZONE", JSON.stringify(FUN_ZONE.children.map(item => ({
        title: item.configTitle,
        children: item.children.map(item1 => ({
            title:JSON.parse(item1.configValue.replace(/[\r|\n|\t]/g," ")).title,
            url: JSON.parse(item1.configValue.replace(/[\r|\n|\t]/g," ")).url,
            content: JSON.parse(item1.configValue.replace(/[\r|\n|\t]/g," ")).content,
            digest: JSON.parse(item1.configValue.replace(/[\r|\n|\t]/g," ")).digest,
        }))
    }))))
    const handleScreenAuto = () => {
    const designDraftWidth = 2360; // 设计稿的宽度
    const designDraftHeight = 1080; // 设计稿的高度
    this.init = true;

  // 根据屏幕的变化适配的比例
  const scale =
      document.documentElement.clientWidth / document.documentElement.clientHeight < designDraftWidth / designDraftHeight
        ? document.documentElement.clientWidth / designDraftWidth
        : document.documentElement.clientHeight / designDraftHeight;
        if (document.documentElement.clientWidth > 2360) {
          (document.querySelector("#screen")).style.transform = ``;
          (document.querySelector("#screen")).classList.remove("screen");
          return
        }
        // 缩放比例
        (document.querySelector("#screen")).classList.add("screen");
        (document.querySelector("#screen")).style.transform = `scale(${scale}) translate(-50%)`;
        (document.querySelector("#screen")).style.left = `50%`;
        };

        handleScreenAuto()
          window.onresize = () => handleScreenAuto();
          })
  }
})
</script>

<style lang="less">
body {
  background: black;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  width: 100%;
  height: fit-content;
}

.el-popup-parent--hidden {
  overflow: hidden !important;
}

.el-carousel__button {
  background: rgba(154, 235, 163, 1) !important;
  border-radius: 0.5rem;
  height: 0.5rem !important;
  margin-top: 3rem !important;
}
.mobile_wrapper {
  display: none;
}

@media (max-width: 768px) {
  .mobile_wrapper {
    display: block;
    width: 100%;
    font-family: HarmonyOS sans-serif;
    .healink {
      background: rgba(154, 235, 163, 1);
      height: fit-content;
    }
  }
}
</style>
