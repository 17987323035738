import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3d482a18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile_card" }
const _hoisted_2 = { class: "card_icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "card_title" }
const _hoisted_6 = { class: "dec" }
const _hoisted_7 = { class: "dec2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.$props.url,
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$props.title), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$props.content), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$props.digest), 1)
    ])
  ]))
}