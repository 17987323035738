import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router';
import vue3GoogleLogin from 'vue3-google-login'
import "./index.less";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 设置 rem 函数
function setRem() {
  // 1200 默认大小16px; 1200px = 120rem ;每个元素px基础上/16
  const screenWidth = 800
  const scale = screenWidth / 16
  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  if (document.documentElement.clientWidth > 768) {
    return;
  }
  // 得到html的Dom元素
  const htmlDom = document.getElementsByTagName('html')[0]
  // 设置根元素字体大小
  htmlDom.style.fontSize = htmlWidth / scale + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem()
}
const Vue = createApp(App).use(router).use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  Vue.component(key, component)
}
Vue.mount('#app');