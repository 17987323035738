import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": _ctx.visible,
    center: "",
    "modal-class": "ai-header-dialog",
    "lock-scroll": true,
    width: "408px",
    style: {"height":"620px","border-radius":"16px"},
    "before-close": _ctx.handleCloseDialog,
    "append-to-body": true,
    "append-to": "body"
  }, {
    default: _withCtx(() => [
      _createElementVNode("iframe", {
        style: {"height":"620px","width":"408px"},
        src: _ctx.url,
        frameborder: "0"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["model-value", "before-close"]))
}