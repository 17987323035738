import { reactive } from 'vue'

export const store = reactive({
  login: false,
  register: false,
  mobileLogin: false,
  mobileRegister: false,
  mobileSignUp: false,
  isLogin: false,
  aiVisible: false,
  bsVisible: false,
  handleCloseAiModal() {
    this.aiVisible = false;
  },
  handleOpenAiModal() {
    console.log('222')
    this.aiVisible = true;
  },
  handleCloseMobile() {
    this.mobileLogin = false;
    this.mobileRegister = false;
    this.mobileSignUp = false;
  },
  handleOpenMobileRegister() {
    this.mobileRegister = true;
  },
  handleOpenMobileSignUp() {
    this.mobileRegister = false;
    this.mobileLogin = false;
    this.mobileSignUp = true;
  },
  handleOpenMobileLogin() {
    this.mobileLogin = true;
  },
  handleOpenLogin() {
    this.login = true
  },
  handleSetIsLogin() {
    this.isLogin = true
  },
  handleCloseLogin() {
    this.login = false
  },
  handleOpenRegister() {
    this.register = true
  },
  handleCloseRegister() {
    this.register = false
  },
})