
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
  components: {},
  props: {
    visible: Boolean,
    handleClose: Function,
  },
  mounted() {
    if (localStorage.getItem("VIDEO_1")) {
      this.url = JSON.parse(localStorage.getItem("VIDEO_1") || "").demo;
    }
  },
  data() {
    return {
      url: "",
    };
  },
  methods: {
    handleCloseDialog() {
      this.$props.handleClose?.();
    },
  },
});
