import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "home"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Detail = _resolveComponent("Detail")!
  const _component_LifeStyle = _resolveComponent("LifeStyle")!
  const _component_Users = _resolveComponent("Users")!
  const _component_Story = _resolveComponent("Story")!
  const _component_AIHeader = _resolveComponent("AIHeader")!
  const _component_AIContentVue = _resolveComponent("AIContentVue")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.store.isLogin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Header),
          (!_ctx.store.isLogin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_Detail),
                _createVNode(_component_LifeStyle),
                _createVNode(_component_Users),
                _createVNode(_component_Story)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_AIHeader),
          _createVNode(_component_AIContentVue)
        ])),
    _createVNode(_component_Footer)
  ], 64))
}